import React from 'react'
import {
	Spinner,
	Flex,
	List,
	ListItem,
	Button,
	Heading,
	VStack,
	Stack,
	Box,
	Text,
	Image,
	keyframes,
	Link,
} from '@chakra-ui/react'
import getGAClientId from '../../../../../utils/getGAClientId'
import Card from '../../../atoms/Card'
import { CheckCircleRounded, InfoRounded } from '../../../atoms/Icon'

// import useBeginCheckoutEvent from '../../../../hooks/useBeginCheckoutEvent'
import useDataLayerPush from '../../../../hooks/useDataLayerPush'
import { useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import configClient from '../../../../../../client/config.client'
import useEntities from '../../../../hooks/useEntities'
import loadable from '@loadable/component'

const MembershipPerks = loadable(
	() => import(/* webpackPrefetch: true */ '../../../organisms/ModalSubscriptionCheckout/membershipPerks.tsx'),
	{
		ssr: false,
	}
)

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: configClient.api.baseUrl,
})

// /**
//  *  Fancy background
//  */
// const gradient = keyframes`
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
// `
// const animatedBackground = {
//   background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
//   // background: 'linear-gradient(120deg, #0066CC 62%, #06CB, #0066CC 63%) right/250% 100%', // Glare flash
//   backgroundSize: '400% 100%',
//   animation: `${gradient} 30s ease infinite`,
//   willChange: 'background-position',
// }

const SubscriptionUpsell = () => {
	/** get stripe sdk for checkout redirect! */
	const stripe = useStripe()

	const handleCreateCheckoutSession = async () => {
		// Set redirect urls (only when window is defined)
		if (typeof window !== 'undefined' && window) {
			const cancelUrl = `${window.location.href}?cancel=true`
			const successUrl = `${window.location.protocol}//${window.location.host}/dashboard` // send user to dashboard on successful subscription
			const analyticsClientId = getGAClientId()

			// do the post request
			/** POST Contribution and retrieve Stripe Checkout session ID */
			try {
				const response = await instance.post(`/v2/subscriptions/checkout`, {
					successUrl,
					cancelUrl,
					interval: 'year', // set to "month" for monthly membership, "year" for yearly membership
					analyticsClientId: analyticsClientId || undefined, // don't include when its null
				})

				const { checkoutSession } = response.data.data

				// Fire off "begin_checkout" event
				useDataLayerPush({ ecommerce: null }) // Important! Clear the previous ecommerce object
				useDataLayerPush({
					event: 'begin_checkout',
					ecommerce: {
						currency: checkoutSession.currency.toUpperCase(),
						value: Number((checkoutSession.amount_total / 100).toFixed(2)),
						coupon: null,
						items: checkoutSession.items.map((item, index) => {
							return {
								index: index,
								item_id: item.price.product, // use the Stripe product id
								coupon: null,
								discount: Number((item.amount_discount / 100).toFixed(2)),
								price: Number((item.amount_total / 100).toFixed(2)), // use price once discount is applied
								quantity: item.quantity,
							}
						}),
					},
				})
				// Redirect user to Stripe Checkout page
				const result = await stripe.redirectToCheckout({ sessionId: checkoutSession.id })
				if (result.error) {
					throw new Error('Stripe error : ' + result.error)
				}
			} catch (err) {
				console.error('Subscription checkout failed! ', err)
			}
		}
	}

	const [isFetchingInvoices, invoices] = useEntities('invoices')
	const [isFetchingEvents, events] = useEntities('events')

	return (
		<Card p="2rem" variant="main" mb="2rem" mx={['1rem', 0]} borderRadius={['xl']}>
			<Box margin="auto">
				<MembershipPerks
					currency={{ symbol: '$', code: 'USD' }}
					savings={'40%'}
					price={{ full: 9999, discounted: 5999 }}
				/>
				<VStack justifyContent={'center'} w="full">
					<Button onClick={handleCreateCheckoutSession} target="_blank" rel="noreferrer" mb=".5rem">
						Become a Member Today
					</Button>
					<Text display="block" fontSize="0.75rem">
						By continuing, you agree to our{' '}
						<Link
							color="link"
							href="https://www.vidday.com/terms-of-use"
							target="_blank"
							alt="Visit our Terms of Use page">
							Terms of Use
						</Link>{' '}
						and{' '}
						<Link
							color="link"
							href="https://www.vidday.com/privacy-policy"
							target="_blank"
							alt="Visit our Privacy Policy page">
							Privacy Policy
						</Link>
						.
					</Text>
				</VStack>
			</Box>
		</Card>
	)
}

export default SubscriptionUpsell

{
	/* <Card
  px={['1rem', '2rem']}
  py="1rem"
  variant="main"
  mb="2rem"
  mx={['1rem', 0]}
  borderRadius={['xl']}
  sx={animatedBackground}>
  <Stack
    w="full"
    spacing="1rem"
    justifyContent="start"
    direction={['column', 'row']}
    alignItems="center">
    <Box maxW="300px">
      <Image
        src="/assets/images/graphics/billing/vidday-one-free-month.png"
        alt="Claim one month free"
      />
    </Box>
    <Box>
      <VStack align="left" ml={['0', '2rem']}>
        <Heading color="white" size="xl" mt="2rem">
          Claim your free month!
        </Heading>
        <List color="white">
          <ListItem ml=".125rem">
            <CheckCircleRounded /> Free VidDays
          </ListItem>
          <ListItem ml=".125rem">
            <CheckCircleRounded /> Priority Processing
          </ListItem>
          <ListItem ml=".125rem">
            <CheckCircleRounded /> Unlimited Republishes
          </ListItem>
          <ListItem ml=".125rem">
            <CheckCircleRounded /> eCards for All Occasions
          </ListItem>
        </List>
        <Stack direction={['column-reverse', 'row']} alignItems={['left', 'center']}>
          {isFetchingInvoices || isFetchingEvents ? (
            <Spinner color="white" m="3rem" />
          ) : Object.keys(invoices).length > 0 ? (
            <Button
              onClick={handleCreateCheckoutSession}
              target="_blank"
              rel="noreferrer"
              variant="invert"
              borderColor="white"
              w={['full', '170px']}
              my="1rem">
              Become a Member
            </Button>
          ) : (
            <Text color="white">
              <InfoRounded ml="4px" me=".25rem" mt="-.125rem" width="1rem" />
              On your next checkout, become a member.
            </Text>
          )}
          <Flex direction={['column']} spacing="0" pl=".5rem">
            <Text color="white" fontWeight="700">
              30 days free
            </Text>
            <Text color="white">$9.99/month after • Cancel anytime</Text>
            {/*
            <Text color="white" fontSize="xs">
              Personal use only - Enterprise users &nbsp;
              <chakra.a
                _hover={{ textDecoration: 'underline' }}
                color="var(--vidday-colors-link)"
                href={'mailto:enterprise@vidday.com'}
                title={'enterprise'}
                target="_blank"
                rel="noreferrer">
                contact us
              </chakra.a>
            </Text>
          </Flex>
        </Stack>
      </VStack>
    </Box>
  </Stack>
</Card> */
}
